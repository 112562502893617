import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/questionnaire",
          name: "questionnaire",
          component: () =>
            import("@/view/modules/questionnaire/Questionnaire.vue"),
          children: [
            {
              path: "templates-list",
              name: "questionnaire-templates-list",
              component: () =>
                import("@/view/modules/questionnaire/templates-list.vue")
            },
            {
              path: "generate-template/:id",
              name: "questionnaire-generate-template",
              component: () =>
                import("@/view/modules/questionnaire/generate-template.vue")
            },
            {
              path: "template-question-edit",
              name: "questionnaire-template-question-edit",
              component: () =>
                import(
                  "@/view/modules/questionnaire/template-question-edit.vue"
                )
            },
            {
              path: "template-question-list/:id",
              name: "questionnaire-template-question-list",
              component: () =>
                import(
                  "@/view/modules/questionnaire/template-question-list.vue"
                )
            },
            {
              path: "question-list",
              name: "questionnaire-question-list",
              component: () =>
                import("@/view/modules/questionnaire/question-list.vue")
            },
            {
              path: "generate-question/:id",
              name: "questionnaire-generate-question",
              component: () =>
                import("@/view/modules/questionnaire/generate-question.vue")
            }
          ]
        }
      ]
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
