/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d98ab0e8-d57b-42cd-8446-a509dc32e1df",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_RaTdVqsG1",
    "aws_user_pools_web_client_id": "16p8rnhfrvrsn5htl2qcpv5e6q",
    "oauth": {},
    "aws_user_files_s3_bucket": "oneaudit-evidences155926-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
